import { AddToCurrentCartRequest } from '@wix/ambassador-ecom-v1-cart/types';
import { addToCurrentCart } from '@wix/ambassador-ecom-v1-cart/http';

import {
  ControllerParams,
  IHttpClient,
  ISiteApis,
} from '@wix/yoshi-flow-editor';

const STORES_APP_DEF_ID = '1380b703-ce81-ff05-f115-39571d94dfcd';

export class CartService {
  site: ISiteApis;
  httpClient: IHttpClient;

  constructor(controllerParams: ControllerParams) {
    const { controllerConfig, flowAPI } = controllerParams;
    this.site = controllerConfig.wixCodeApi.site;
    this.httpClient = flowAPI.httpClient;
  }

  async addToCart(request: AddToCurrentCartRequest) {
    await this.httpClient.request(addToCurrentCart(request));
  }

  async postAddToCartNavigation(postAction: string) {
    const publicApi = await this.site.getPublicAPI(STORES_APP_DEF_ID);
    switch (postAction) {
      case 'goToCartPage':
        await publicApi.navigate.toCart();
        break;
      case 'openMiniCart':
        await publicApi.cart.reloadCart();
        await publicApi.cart.showMinicart();
        break;
      case 'successPopup':
        await publicApi.cart.reloadCart();
        await publicApi.cart.showTinycart();
        break;
      case 'stayOnPage':
        await publicApi.cart.reloadCart();
        break;
      default:
        break;
    }
  }

}
